.embla {
  overflow: hidden;
}
.embla__container {
  display: flex;
}
.embla__slide {
  flex: 0 0 100%;
  min-width: 0;
  cursor: grab;
  &:active {
    cursor: grabbing;
  }
}
