@use "variables" as v;

.btn {
  $shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  @include font-family-inter-semibold();
  box-shadow: $shadow;
  padding: 10px 15px;
  cursor: pointer;

  &--fw {
    width: 100%;
  }

  &--dotted {
    background-color: white;
    color: $primary;
    border-style: dashed;
    box-shadow: $shadow;
  }

  &:hover {
    text-decoration: none;
  }

  &.btn-primary {
    background-color: #fff;
    border-color: var(--border-color);
    color: var(--btn-main-caption-color);
    border-radius: 5px;
    border-width: 1px;

    &.filled {
      color: #ffffff !important;
      background-color: var(--border-color) !important;
    }

    &.btn-toggle {
      background-color: var(--light-background);
    }
  }

  &.btn-secondary {
    background-color: #fff;
    border: 1px solid #9ba1ab;
    border-radius: 5px;
    color: #9ba1ab;

    &.caption {
      border: none;
      color: var(--border-color);
      box-shadow: none;
    }

    &.caption-with-bordered-responsive {
      color: var(--border-color);
      @media (max-width: v.$breakpoint-mobile) {
        box-shadow: none;
      }
    }
  }

  &.btn-primary__education {
    background-color: #3061c3;
    border-radius: 5px;
    color: #fff;
  }

  &.btn-secondary__education {
    color: #3061c3;
    background-color: transparent;
    border: 1px solid #3061c3;
  }

  &.btn-tertiary__education {
    color: #3061c3;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  &.btn-primary__education,
  &.btn-secondary__education {
    border-radius: 0.875rem;
    &:hover {
      box-shadow: 3px 0 0.9375rem rgba(50, 90, 244, 0.3);
    }
  }

  &.btn-primary__education,
  &.btn-secondary__education,
  &.btn-tertiary__education {
    box-shadow: none;

    &:focus-visible {
      border: 2px solid #000;
    }

    &.btn-md {
      font-size: 1rem;
      height: 2.5rem;
      padding: 0 2rem;
    }

    &.btn-lg {
      font-size: 1.125rem;
      height: 3.5rem;
      padding: 0 2.5rem;
    }
  }

  &.btn-primary {
    z-index: 2;
  }

  &.btn-secondary {
    z-index: 1;
  }

  &.btn-action {
    border-color: var(--border-color);
    background-color: var(--border-color);
    color: #fff;
  }

  &[disabled] {
    background-color: #fafafa;
    color: #777;
    cursor: not-allowed;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
}
