@use "../../../styles/variables" as v;
@use "../edu-mixins.module" as mixins;

.landing-page {
  &__container {
    @include mixins.container;
  }

  &__header {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 15px 0px;
    position: sticky;
    top: 0;
    z-index: 2;

    > [class*="__container"] {
      height: 4.25rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 1.25rem;
    }
  }

  &__akko-logo {
    width: 7rem;
  }
}
