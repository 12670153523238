@use "sass:math";

$black: #060920;
$soft: #9ba1ab;
$xsoft: #f3f3f3;
$bgsoft: #e8e8e8;
$bordersoft: #eaeaea;
$bgsoftred: #fef9fa;
$_1px: math.div(1rem, 16) rem;
$_2px: math.div(2, 16) rem;
$_3px: math.div(3, 16) rem;
$_4px: math.div(4, 16) rem;
$_5px: math.div(5, 16) rem;
$_6px: math.div(6, 16) rem;
$_7px: math.div(7, 16) rem;
$_8px: math.div(8, 16) rem;
$_9px: math.div(9, 16) rem;
$_10px: math.div(10, 16) rem;
$_11px: math.div(11, 16) rem;
$_12px: math.div(12, 16) rem;
$_13px: math.div(13, 16) rem;
$_14px: math.div(14, 16) rem;
$_15px: math.div(15, 16) rem;
$_16px: 1rem;
$_17px: math.div(17, 16) rem;
$_18px: math.div(18, 16) rem;
$_19px: math.div(19, 16) rem;
$_20px: math.div(20, 16) rem;
$_21px: math.div(21, 16) rem;
$_22px: math.div(22, 16) rem;
$_23px: math.div(23, 16) rem;
$_24px: math.div(24, 16) rem;

$font-family-cera: "Cera";
$font-family-inter: "Inter";
$font-family-conforta: "comfortaa";

@mixin font-family-cera {
  font-family: $font-family-cera;
}

@mixin font-family-inter-light {
  font-family: $font-family-inter;
  font-weight: 300;
}

@mixin font-family-inter {
  font-family: $font-family-inter;
  font-weight: 400;
}

@mixin font-family-inter-medium {
  font-family: $font-family-inter;
  font-weight: 500;
}

@mixin font-family-inter-semibold {
  font-family: $font-family-inter;
  font-weight: 600;
}

@mixin font-family-conforta {
  font-family: $font-family-conforta;
}

$primary-text-color: #060920;
$secondary-text-color: #9ba1ab;

$breakpoint-mobile: 768px;
$breakpoint-large: 992px;
$breakpoint-large-tablet: 1024px;
$breakpoint-xxlarge: 1400px;
