@use "../../../styles/variables" as v;
@use "../edu-mixins.module" as mixins;

.landing-page {
  &__container {
    @include mixins.container;
  }

  &__coverage-section {
    background-color: #2e6993;
    color: white;
    text-align: center;
    position: relative;
    h2 {
      font-size: 2.5rem;
    }
    &-decorator {
      position: absolute;
      left: 0;
      top: -2px;
    }
  }

  &__coverage-section-title {
    display: flex;
    padding: 6rem 1.25rem 0;
    align-items: center;
    margin-bottom: 1.5rem;
    justify-content: center;
    max-width: 37.5rem;
    margin-inline: auto;
    line-height: 1;
    letter-spacing: -0.125rem;
    gap: 1.5rem;
  }

  &__arrow-right {
    transform: scaleX(-1);
  }

  &__coverage-cards {
    padding: 0 1.25rem 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3rem;
  }

  @media (min-width: v.$breakpoint-mobile) {
    &__coverage-section {
      > p {
        font-size: 1.125rem;
      }
    }

    &__coverage-section-title {
      padding-top: 11rem;
      max-width: 50rem;

      > h2 {
        font-size: 3rem;
      }
    }
  }

  @media (min-width: v.$breakpoint-large-tablet) {
    &__coverage-cards {
      flex-direction: row;
      gap: 1.5rem;
      margin-top: 3rem;
      > div {
        flex: 1;
      }
    }
  }

  @media (min-width: v.$breakpoint-mobile) {
    &__coverage-section-title {
      > h2 {
        font-size: 4rem;
      }
    }
  }
}
