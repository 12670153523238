@use "../../../styles/variables" as v;
@use "../edu-mixins.module" as mixins;

.landing-page {
  &__container {
    @include mixins.container;
  }

  &__insurance-section {
    background-color: #eff3fa;
    padding: 2.5rem 1.25rem;

    p {
      margin: 1.25rem 0 3.5rem 0;
    }
  }

  &__insurance-cards {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
  }

  @media (min-width: v.$breakpoint-mobile) {
    &__insurance-section-title {
      display: flex;
      gap: 1.25rem;
      > h2 {
        width: 47%;
      }
      > p {
        width: 53%;
        max-width: 33rem;
        margin: 0 auto 0;
        font-size: 1.125rem;
      }
    }

    &__insurance-cards {
      flex-wrap: wrap;
      flex-direction: row;
      padding-top: 2.25rem;
      > div:first-child {
        width: 50%;
      }
    }
  }

  @media (min-width: v.$breakpoint-large-tablet) {
    &__insurance-cards {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      > div:first-child {
        width: 100%;
      }
    }
  }
}
