@use "../../styles/variables" as v;

.landing-page {
  h1,
  h2 {
    font-family: "Rubik", sans-serif;
    font-size: 1.5rem;
  }

  @media (min-width: v.$breakpoint-mobile) {
    h1,
    h2 {
      font-size: 2.5rem;
    }

    p {
      font-size: 1.125rem;
    }
  }

  @media (min-width: v.$breakpoint-large-tablet) {
    h1,
    h2 {
      font-size: clamp(2rem, 2.5vw, 3.0625rem);
      white-space: pre-line;
    }

    section {
      padding-inline: 5rem;
    }
  }
}
