@use "../../../styles/variables" as v;
@use "../edu-mixins.module" as mixins;

.landing-page {
  &__plan-section {
    @include mixins.container;

    padding: 2.5rem 1.25rem;
    text-align: center;
    margin-top: 2.5rem;

    h2 {
      margin: 0;
    }
  }

  &__plan-cards {
    margin-top: 2rem;
  }

  @media (min-width: v.$breakpoint-mobile) {
    &__plan-section {
      @include mixins.container;

      padding-inline: 4.5rem;

      h2 {
        padding: 0.625rem;
      }
    }

    &__plan-cards {
      --columns: 2;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      > div {
        max-width: calc(100% / var(--columns));
        flex: 0 0 calc(100% / var(--columns));
      }
    }
  }

  @media (min-width: v.$breakpoint-large-tablet) {
    &__plan-cards {
      --columns: 4;
    }
  }
  @media (min-width: v.$breakpoint-xxlarge) {
    &__plan-section {
      h2 {
        font-size: 4rem;
      }
    }
  }
}
