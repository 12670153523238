@use "../../../styles/variables" as v;
@use "../edu-mixins.module" as mixins;

.landing-page {
  &__container {
    @include mixins.container;
  }

  &__hero-mobile {
    width: 100%;
  }

  &__hero-browser {
    display: none;
  }

  &__school-logo {
    width: 9.375rem;
    height: 9.375rem;
    margin-bottom: 1rem;
  }

  &__school-details {
    padding: 2.5rem 1.25rem;
    padding-top: 2rem;

    button {
      margin-top: 2rem;
      width: 100%;
    }
  }

  @media (min-width: v.$breakpoint-mobile) {
    &__hero-mobile {
      display: none;
    }

    &__hero-browser {
      display: initial;
      width: 30%;
      margin-top: -2rem;
      margin-left: auto;
      margin-right: 10%;
      img {
        rotate: -45deg;
        width: 145%;
        border-radius: 80px;
      }
    }

    &__school-logo {
      width: 10rem;
      height: 10rem;
      margin-bottom: 1rem;
    }

    &__school-section {
      overflow: hidden;

      > div[class*="__container"] {
        display: flex;
        padding-top: 2.5rem;
      }
    }

    &__school-details {
      width: 62%;
      button {
        width: max-content;
      }
    }
  }

  @media (min-width: v.$breakpoint-large-tablet) {
    h1,
    h2 {
      font-size: clamp(2rem, 2.5vw, 3.0625rem);
    }

    &__school-logo {
      width: 10rem;
      height: 10rem;
      margin-bottom: 1rem;
    }

    &__school-section {
      display: flex;
      padding-top: 2.5rem;
      padding-bottom: 0rem;
    }

    &__school-details {
      > p {
        max-width: 23.75rem;
      }
    }

    &__hero-browser {
      img {
        font-size: clamp(0.75rem, 1vw, 1rem);
        margin-left: clamp(0rem, 2.3vw, 10rem);
        margin-top: -8rem;
        rotate: -45deg;
        width: 55em;
        border-radius: 80px;
      }
    }
  }
}
