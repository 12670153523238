@use "../../styles/variables" as v;

.plan-card {
  padding: 0.5rem;
  &__container {
    height: 100%;
    text-align: center;
    padding: 1.5rem;
    background-color: #eff1f4;
    border-radius: 1rem;
    border: 2px solid;
    border-color: #eff1f4;
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
      box-shadow 0.3s;
    &:hover {
      border-color: #3061c3;
    }
  }

  span {
    display: block;
  }
  button {
    margin-top: 1.5rem;
  }
  &__title,
  &__value {
    font-weight: 700;
    font-size: 2rem;
    display: block;
  }
  &__coverage {
    font-size: 1rem;
    display: block;
  }
  &__value {
    margin-top: 1rem;
  }

  @media (min-width: v.$breakpoint-large-tablet) {
    &__title,
    &__value {
      font-size: clamp(1.25rem, 2vw, 2rem);
    }
    &__button {
      font-size: clamp(1rem, 1vw, 1.125rem) !important;
    }
  }
}
