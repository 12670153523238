@use "../../styles/variables" as v;

.coverage-card {
  max-width: 23rem;
  &__image {
    width: 100%;
  }

  &__title {
    font-family: "Rubik", sans-serif;
    font-size: 2.125rem;
    white-space: pre-line;
    margin: 0.5rem 0;
  }
  &__description {
    font-size: 1rem;
  }
}
