@use "../../../styles/variables" as v;

.landing-page {
  &__footer {
    background: white;
    box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1);
    text-align: center;
    padding: 0.65rem;
    &-akko-logo {
      > span {
        font-size: 0.875rem;
        margin-right: 0.5rem;
      }
      > svg {
        width: 5rem;
      }
    }
  }
}
