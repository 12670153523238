@use "../../../styles/variables" as v;
@use "../edu-mixins.module" as mixins;

.landing-page {
  &__container {
    @include mixins.container;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    > &-column {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
    }
  }

  &__review-heading,
  &__review-actions {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  &__ratings-link {
    margin: auto auto 0;
    display: flex;
    align-items: center;
  }

  &__review-section {
    margin: 2.5rem 1.25rem;

    > div {
      background-color: #eef2fa;
      border-radius: 1rem;
      padding: 2rem 1.6rem;
    }
  }

  &__review-slide {
    background: #fff;
    border-radius: 1.5rem;
    padding: 1.75rem 2rem;
    font-size: 18px;
    color: #333;
    height: 100%;
    margin-inline: 0.5rem;

    blockquote {
      margin-top: 2rem;
      position: relative;

      &::before,
      &::after {
        font-weight: bolder;
        font-size: 3em;
        line-height: 0;
        position: absolute;
        opacity: 0.7;
      }

      &::before {
        content: "\201C"; /* Unicode for left curly quote (“) */
        top: 0;
        left: 0;
      }

      &::after {
        font-weight: 700;
        content: "\201D"; /* Unicode for right curly quote (”) */
        bottom: -2rem;
        right: 0;
      }
    }

    > figcaption {
      color: #1c2022;
      font-weight: 500;
    }
  }

  &__review-logos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2.5rem;
    margin-top: auto;

    > img {
      height: 100%;
      width: auto;
    }
  }

  &__rated-by-title {
    font-size: 1.125rem;
    color: #6d7482;
    text-align: center;
    display: block;
  }

  &__rated-by-logos {
    --columns: 2;

    display: grid;
    grid-template-columns: repeat(var(--columns), 1fr);
    margin-top: 1rem;
    gap: 1rem 3rem;

    div {
      display: flex;
      align-items: center;

      a.landing-page__rated-by-logos-link {
        margin-top: 5rem;
        width: 100%;

        &:hover {
          opacity: 0.8;
        }

        img {
          width: 100%;
        }
      }
    }

    > :last-child:nth-child(odd) {
      grid-column: 1 / -1;
      justify-self: center;
      width: auto;
    }
  }

  @media (min-width: v.$breakpoint-mobile) {
    &__review-section {
      > h2 {
        font-size: 3rem;
      }
      max-width: 100vw;
    }

    &__rated-by-title {
      text-align: left;
    }

    &__rated-by-logos {
      display: flex;
      gap: 5rem;

      div {
        flex: 3;
        &:last-child {
          flex: 1;
        }
      }
    }
  }

  @media (min-width: v.$breakpoint-large-tablet) {
    &__review-heading,
    &__review-actions {
      min-width: 0;
      flex: 1;
    }

    &__container {
      > &-column:first-child {
        flex-direction: row;
      }
    }

    &__review-section {
      > div {
        padding: 3.5rem;
      }
    }
  }
}
