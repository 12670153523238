@use "../../../styles/variables" as v;
@use "../edu-mixins.module" as mixins;

.landing-page {
  &__info-section {
    padding: 2.5rem 1.25rem;
  }

  &__info-section {
    @include mixins.container;
    text-align: center;

    p {
      margin-top: 1.25rem;
    }
  }
}
