@use "../../styles/variables" as v;

.check-list {
  list-style: none;
  gap: 0.75rem;
  text-align: left;
  margin-top: 1.5rem;
  padding: 0;
  &__item {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;
    gap: 0.5rem;
    > span {
      display: inline-block;
      line-height: 1;
      font-size: 0.875rem;
    }
  }
}
