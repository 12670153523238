@use "../../styles/variables" as v;

.insurance-card {
  padding: 1.5rem;
  background-color: white;
  border-radius: 1rem;

  &__icon {
    width: 2rem;
    height: 2rem;
  }

  &__title {
    display: block;
    font-weight: 700;
    font-size: 1.125rem;
    margin: 1rem 0 0.5rem;
  }

  &__description {
    color: #7a7a7a;
    margin: 0;
  }

  @media (min-width: v.$breakpoint-mobile) {
    padding: 2rem;

    &__title {
      font-size: 1.375rem;
    }

    &__description {
      font-size: 1.125rem;
    }
  }
}
