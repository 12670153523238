@use "../../../styles/variables" as v;
@use "../edu-mixins.module" as mixins;

.landing-page {
  &__protection-section {
    @include mixins.container;

    padding: 2.5rem 1.25rem;
    display: flex;
    flex-direction: column;
    > img {
      width: 100%;
      max-width: 35rem;
      align-self: center;
    }

    h2 {
      margin-top: 2rem;
    }

    p {
      margin-top: 1.25rem;
    }
  }

  @media (min-width: v.$breakpoint-mobile) {
    &__protection-section {
      flex-direction: row;
      img {
        width: 46%;
      }
      > div {
        margin-left: 3rem;
        h2 {
          max-width: 26.25rem;
        }
      }
    }
  }

  @media (min-width: v.$breakpoint-large-tablet) {
  }
}
